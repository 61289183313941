body {
    margin: 0;
    font-family: 'Roboto', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    font-weight: 400;
    color: #393f52;
}

input,
select,
textarea {
    font-size: 16px;
}

/* change scroll browser */
.custom-scroll-bar::-webkit-scrollbar {
    width: 5px;
}

.custom-scroll-bar::-webkit-scrollbar-track {
    background-color: rgba(25, 147, 147, 0.1);
    border-radius: 10px;
}

.custom-scroll-bar::-webkit-scrollbar-thumb {
    background-color: rgb(175, 130, 253);
    border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

/* custom tooltip */
.ant-tooltip .ant-tooltip-arrow:before {
    background-color: var(--tc-system-info-50);
}

/* custom Gallery photos */
.pswp__counter {
    display: none;
}

.pswp__button.pswp__total-page {
    position: absolute;
    bottom: 30px;
    left: 50%;
    color: white;
    font-size: 20px;
}

.pswp__rental-name {
    position: absolute;
    top: 30px;
    left: 40%;
    color: white;
    font-size: 20px;
}

/* ant-popover .ant-popover-content */
.ant-popover .ant-popover-content {
    min-width: 150px;
}

.body-edit-table-drawer {
    padding: 12px !important;
}

.body-card-services-drawer {
    padding: 0 !important;
}

.notification-dropdown {
    top: 65px !important;
}

.notification-dropdown .ant-dropdown-menu {
    border-radius: 0 0 12px 12px !important;
    box-shadow: none !important;
}

.ant-picker-dropdown .ant-picker-panel-container {
    display: inline-flex;
    animation: none;
    flex-wrap: nowrap;
    direction: ltr;
}

/* ant-picker */
.ant-picker-panel-container {
    .ant-picker-presets {
        min-height: 25rem !important;
    }

    @media (max-width: 800px) {
        overflow: scroll !important;
        height: 400px;

        .ant-picker-panel-layout {
            flex-direction: column !important;
        }
    }
}

.ant-picker-panel-layout {
    flex-direction: column !important;
}

.ant-picker-panel-layout .ant-picker-presets {
    max-width: 100% !important;
    min-height: 10rem !important;
}

.ant-picker-panel-container .ant-picker-panel-layout .ant-picker-presets {
    max-width: 100% !important;
    min-height: 5rem !important;
}

.ant-picker-panel-container .ant-picker-panel-layout .ant-picker-panels,
.ant-picker-panel-container
    .ant-picker-panel-layout
    .ant-picker-datetime-panel {
    flex-direction: column !important;
}

.ant-select .ant-select-selection-wrap {
    height: 100% !important;
}
