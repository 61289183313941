body,
.root,
#root {
    --text-primary: #7357ff;
    --text-high-em: #110c22;
    --text-med-em: #676472;
    --text-low-em: #b3b1b8;
    --icon-high-em: #4f4b5c;
    --icon-med-em: #8d8a95;
    --icon-low-em: #c6c5ca;
    --icon-disabled: #d9d8dc;
    --system-error-500: #f03d3d;
    --tc-outlines-med: #ececed;
    --tc-text-primary-bold: #3c28a4;
    --text-info: #0084ff;
    --tc-system-info-50: #e9f4ff;
    --tc-system-error-50: #fff1f1;
    --surface-info: #0084ff;
    --tc-primary-p-400: #8b75ff;
    --shadow-e-02: 0px 2px 4px -2px rgba(17, 12, 34, 0.12);
    --shadow-pressed-primary:
        0px 0px 0px 4px #e2dcff, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
    --shadow-pressed-gray:
        0px 0px 0px 4px #ececed, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
    --shadow-pressed-info:
        0px 0px 0px 4px #c2e2ff, 0px 2px 4px 0px rgba(17, 12, 34, 0.12);
    --tc-primary-p-50: #f1eeff;
    --tc-primary-p-75: #e2dcff;
    --tc-gray-g-75: #ececed;
    --tc-gray-g-25: #f8f8f8;
    --tc-system-info-75: #c2e2ff;
    --tc-system-info-25: #f5faff;
    --tc-system-info-400: #1c92ff;
    --tc-system-success-300: #4dcc8f;
    --tc-surface-high: #ececed;
    --tc-gray-g-200: #d9d8dc;
    --surface-low: #f8f8f8;
}
